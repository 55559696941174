// Font styles
:root {
  font-family: "Inter", sans-serif;
}

@supports (font-variation-settings: normal) {
  :root {
    font-family: "Inter var", sans-serif;
  }
}

// Colors
$primary-color: #f1f1e6;
$secondary-color: #003865;
$tertiary-color: #009ee0;
$primary-accent-color: #d80e62;
$secondary-accent-color: #009ee0;
$tertiary-accent-color: #003865;
$dark-color: #252525;
$light-color: #fff;

body {
  margin: 0;
}
section {
  position: relative;
  *:not(.background-images) {
    position: relative;
    z-index: 2;
  }
  .background-images {
    z-index: 0;
    position: absolute;
  }
}
section + svg.wave {
  position: relative;
  z-index: -3;
  width: 100%;
  max-height: 10em;
  margin-bottom: -1em;
  margin-top: -1em;
}
