@use "base";

section.section-H {
  padding-top: 10em;
  padding-bottom: 10em;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: base.$dark-color;
  background-color: base.$light-color;
  .strong {
    position: relative;
    top: 0.1em;
    height: 1em;
    align-items: flex-end;
    display: inline-flex;
    background-color: base.$secondary-accent-color;
    border-radius: 9999px;
    height: 0.5em;
    strong {
      position: relative;
      top: -0.1em;
      filter: drop-shadow(0 0.1em 0 rgba(255, 255, 255, 1));
    }
  }
  h3 {
    font-size: 3em;
  }
  ul.feat-requirements {
    padding: 0;
    max-width: 60em;
    align-items: center;
    justify-content: center;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    li {
      box-shadow: 0 0.5em 0 base.$primary-color;
      background-color: base.$dark-color;
      color: base.$light-color;
      padding: 0.5em 1em;
      margin: 1em;
      border-radius: 9999px;
    }
  }
}
section.section-H + svg.wave {
  background-color: base.$tertiary-color;
  * {
    fill: base.$light-color;
  }
}
