@use "base";

section.section-M {
  background-color: base.$tertiary-color;
  padding: 4em 4em 5em 4em;
  color: base.$light-color;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  align-items: center;
  div.form-holder {
    h3 {
      color: base.$dark-color;
      text-shadow: 0 0.15em 0 rgba(255, 255, 0, 1);
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 20em;
    background-color: base.$light-color;
    padding: 2em;
    border-radius: 2em;
    box-shadow: 0 0.25em 0 rgba(255, 255, 255, 1);
    border: 0.5em solid base.$dark-color;
  }
  ul {
    padding-inline-start: 0px;
    font-size: 1.25em;
    li {
      padding-bottom: 1em;
      list-style-type: none;
    }
  }
  span {
    font-size: 0.75em;
    font-weight: 700;
    color: base.$dark-color;
    padding-top: 1em;
    // Place it in the center.
    display: flex;
    justify-content: center;
  }
  form {
    color: base.$dark-color;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    gap: 0.2em;
    label {
      display: flex;
      flex-direction: column;
    }
    input {
      display: flex;
      flex-direction: column;
      font-size: 1.5em;
      padding: 0.5em 1em;
      border-radius: 0.5em;
      border: 0.2em solid base.$dark-color;
    }
    button {
      background-color: base.$secondary-color;
      color: base.$light-color;
      font-size: 1.2em;
      max-height: 2.5em;
      display: flex;
      align-items: center;
      box-shadow: 0 0.25em 0 rgba(0, 0, 0, 1);
      // Don't let the button stretch from the flexbox.
      align-self: center;
    }
  }
  .star {
    width: 5em;
    right: 10%;
    bottom: 10%;
  }
  div.inner {
    display: flex;
    flex-wrap: wrap;
    max-width: 60em;
    gap: 4em;
    div {
    }
    figure {
      display: flex;
      margin: 0;
      align-items: center;
      img {
        max-width: 90%;
      }
    }
  }
  figure {
    img {
      border-radius: 1.5em;
      border: 0.75em solid base.$dark-color;
    }
  }
  button {
    box-shadow: 0 0.25em 0 rgba(255, 255, 255, 1);
    color: base.$light-color;
    font-size: 1em;
    padding: 1em 2em;
    background-color: base.$dark-color;
    border-radius: 2.5em;
  }
  button:hover {
    cursor: pointer;
    box-shadow: none;
    transform: translateY(0.25em);
  }
  h3 {
    font-weight: 900;
    font-size: 3em;
    text-shadow: 0 0.15em 0 rgba(0, 0, 0, 1);
    margin-bottom: 0.5em;
  }
  p {
    font-size: 1.5em;
    line-height: 1.5em;
    margin-bottom: 1em;
  }
  .strong {
    position: relative;
    top: 0.1em;
    height: 1em;
    align-items: flex-end;
    display: inline-flex;
    background-color: base.$primary-color;
    border-radius: 9999px;
    height: 0.5em;
    strong {
      font-weight: 900;
      position: relative;
      top: -0.1em;
    }
  }
}
section.block-F + svg.wave {
  background-color: base.$primary-color;
  * {
    fill: base.$tertiary-color;
  }
}

// On mobile devices, set the width of the form to 100%.
@media (max-width: 600px) {
  section.section-M {
    flex-direction: column;
    justify-content: center;
    div.form-holder {
      form {
        gap: 2em;
        button {
          font-size: 2.25em;
          font-weight: 700;
        }
      }
      label {
        font-size: 1.5em;
      }
      max-width: 80vw;
      .form-group {
        width: 100%;
      }
      input {
        width: 70vw;
      }
      span {
        font-size: 1.2em;
      }
    }
  }
}
