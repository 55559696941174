:root {
  font-family: Inter, sans-serif;
}

@supports (font-variation-settings: normal) {
  :root {
    font-family: Inter var, sans-serif;
  }
}

body {
  margin: 0;
}

section {
  position: relative;
}

section :not(.background-images) {
  z-index: 2;
  position: relative;
}

section .background-images {
  z-index: 0;
  position: absolute;
}

section + svg.wave {
  z-index: -3;
  width: 100%;
  max-height: 10em;
  margin-top: -1em;
  margin-bottom: -1em;
  position: relative;
}

@media (max-width: 1100px) {
  body {
    font-size: 60%;
  }
}

@media (max-width: 700px) {
  body {
    font-size: 45%;
  }

  body section.section-A {
    height: 90vh;
    padding: 4em 0 5em;
    overflow-x: hidden;
  }

  body section.section-A nav.desktop {
    display: none;
  }

  body section.section-A nav.mobile {
    display: inline-flex;
  }

  body section.section-A nav.mobile img#logo {
    max-width: 8em;
  }

  body section.section-A ul.feat-visuals {
    grid-template-areas: "\30  \30  \30  b b b b b \31  \31  \31  \31 "
                         "\30  \30  \30  b b b b b c c d d"
                         "f f f b b b b b c c d d"
                         "f f f b b b b b c c d d"
                         "f f f a a a g g g g h h"
                         "\33  \33  \33  a a a g g g g h h"
                         "\33  \33  \33  a a a g g g g h h"
                         "\35  e e e e e \32  \32  \32  i i \34 ";
    width: 60vh;
    height: 60vh;
    margin: 0 -50%;
  }
}

section.section-A {
  color: #252525;
  text-align: center;
  background-color: #f1f1e6;
  min-height: 80vh;
  padding: 4em 4em 5em;
}

section.section-A .tiny-star {
  width: 2em;
  bottom: 0;
  left: 40em;
}

section.section-A .small-star {
  width: 4em;
  top: 20em;
  left: 20em;
}

section.section-A .blue-wiggle-vert {
  width: 6em;
  top: 20em;
  right: 20em;
  transform: rotate(90deg);
}

section.section-A .blue-wiggle-vert path {
  fill: #009ee0;
}

section.section-A .blue-wiggle-horiz {
  width: 6em;
  bottom: 5em;
  left: 25em;
}

section.section-A .blue-wiggle-horiz path {
  fill: #009ee0;
}

section.section-A .purple-wiggles {
  width: 30em;
  bottom: 5%;
  right: 20%;
}

section.section-A .purple-wiggles path {
  fill: #d80e62;
}

section.section-A .big-star {
  width: 10em;
  top: 10em;
  right: 10em;
}

section.section-A svg.wave * {
  fill: #f1f1e6;
}

section.section-A nav.mobile {
  justify-content: space-between;
  width: 90vw;
  display: none;
}

section.section-A nav.mobile div.mobile-icons {
  justify-content: center;
  gap: 1em;
  display: flex;
}

section.section-A nav.mobile div.nav-item-container div {
  text-align: left;
}

section.section-A nav.mobile img {
  max-width: 4em;
}

section.section-A nav.mobile button.dark {
  cursor: pointer;
  color: #fff;
  background-color: #252525;
  border-radius: 9999px;
  padding: 1em 2em;
  font-size: 1.5em;
  font-weight: 600;
  box-shadow: 0 .25em #fff;
}

section.section-A ul li a {
  text-decoration: none;
}

section.section-A ul button.light {
  cursor: pointer;
  color: #252525;
  text-align: center;
  background-color: #fff;
  border: 0 solid #252525;
  justify-content: center;
  padding: .5em;
  font-size: 1.25em;
  font-weight: 400;
  display: flex;
  box-shadow: 0 .25em #000;
  border-radius: 9999px !important;
}

section.section-A ul button.light:hover {
  color: #fff;
  background-color: #252525;
  box-shadow: 0 .25em #fff;
}

section.section-A nav.desktop {
  justify-content: space-around;
  place-items: center;
  width: 60em;
  max-width: 90vw;
  display: inline-flex;
}

section.section-A nav.desktop img {
  max-height: 2.5em;
}

section.section-A nav.desktop a {
  color: inherit;
  text-decoration: none;
}

section.section-A nav.desktop button.dark {
  font-size: inherit;
  cursor: pointer;
  color: #fff;
  background-color: #252525;
  border-radius: 9999px;
  padding: 1em 2em;
  font-weight: 600;
  box-shadow: 0 .25em #fff;
}

section.section-A nav.desktop button.dark:hover {
  box-shadow: none;
  transform: translateY(.25em);
}

section.section-A nav.desktop ul {
  justify-content: space-around;
  padding-inline-start: 0;
  list-style-type: none;
  display: flex;
}

section.section-A nav.desktop ul li {
  margin: 0 1em;
}

section.section-A nav.desktop ul li:hover a {
  color: #003865;
  text-underline-offset: .2em;
  text-decoration: underline;
}

section.section-A em {
  color: #d80e62;
  font-style: normal;
}

section.section-A h3 {
  text-shadow: 0 .15em #fff;
  margin-bottom: .5em;
  font-size: 4em;
  font-weight: 900;
}

section.section-A h4 {
  margin-top: 0;
  font-size: 2em;
}

section.section-A .strong {
  background-color: #009ee0;
  border-radius: 9999px;
  align-items: flex-end;
  height: .5em;
  display: inline-flex;
  position: relative;
  top: .1em;
}

section.section-A .strong strong {
  font-weight: 900;
  position: relative;
  top: -.1em;
}

section.section-A ul.feat-visuals {
  grid-template-rows: repeat(8, 1fr);
  grid-template-columns: repeat(12, 1fr);
  grid-template-areas: "\30  \30  \30  b b b b b \31  \31  \31  \31 "
                       "a a a b b b b b c c d d"
                       "a a a b b b b b c c d d"
                       "a a a b b b b b c c d d"
                       "a a a f f f g g g g h h"
                       "a a a f f f g g g g h h"
                       "a a a f f f g g g g h h"
                       "e e e e e e \32  \32  \32  \32  i i";
  gap: 2em 1em;
  width: 60em;
  height: 35em;
  margin-top: 2em;
  padding: 0;
  display: inline-grid;
}

section.section-A ul.feat-visuals > li {
  margin: 0;
  list-style-type: none;
}

section.section-A ul.feat-visuals > li > * {
  border-radius: inherit;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: 0;
  display: inline-flex;
}

section.section-A ul.feat-visuals > li figure {
  box-sizing: border-box;
  background-color: #fff;
}

section.section-A ul.feat-visuals > li figure img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

section.section-A ul.feat-visuals > li div {
  color: #fff;
  box-sizing: border-box;
  text-align: center;
  border: none;
}

section.section-A ul.feat-visuals > li div p, section.section-A ul.feat-visuals > li div a {
  padding: .8em;
  font-size: 1.5em;
  font-weight: bold;
  line-height: 1.25em;
  text-decoration: none;
}

section.section-A ul.feat-visuals > li div.blue {
  background-color: #009ee0;
  font-size: 1.25em;
}

section.section-A ul.feat-visuals > li div.purple {
  text-align: left;
  background-color: #d80e62;
  font-size: 1.1em;
}

section.section-A ul.feat-visuals > li div.dark {
  background-color: #252525;
}

section.section-A ul.feat-visuals > li div.dark p {
  font-size: 2em;
}

section.section-A ul.feat-visuals > li div.dark a {
  color: #fff;
  font-size: 1.2em;
}

section.section-A ul.feat-visuals > li div.dark:has( > a) {
  border-radius: 2.5em;
}

section.section-A ul.feat-visuals > li div.dark.overflow {
  height: 120%;
  position: relative;
}

section.section-A ul.feat-visuals > li ul.feat-socials {
  text-align: center;
  height: auto;
  padding: 0;
  display: inline;
}

section.section-A ul.feat-visuals > li ul.feat-socials li:hover {
  background-color: #252525;
}

section.section-A ul.feat-visuals > li ul.feat-socials li:hover svg {
  fill: #f1f1e6;
}

section.section-A ul.feat-visuals > li ul.feat-socials > li {
  background-color: #f1f1e6;
  border: .1em solid #fff;
  border-radius: 3em;
  flex-direction: column;
  justify-content: center;
  width: 2em;
  height: 2em;
  margin: .2em;
  list-style-type: none;
  display: inline-flex;
}

section.section-A ul.feat-visuals > li ul.feat-socials > li a {
  color: #252525;
  justify-content: center;
  text-decoration: none;
  display: flex;
}

section.section-A ul.feat-visuals > li:has( > ul.feat-socials) {
  flex-direction: column;
  justify-content: center;
  display: inline-flex;
}

section.section-A ul.feat-visuals > li:first-child {
  grid-area: a;
}

section.section-A ul.feat-visuals > li:nth-child(2) {
  grid-area: b;
}

section.section-A ul.feat-visuals > li:nth-child(3) {
  grid-area: c;
}

section.section-A ul.feat-visuals > li:nth-child(4) {
  grid-area: d;
}

section.section-A ul.feat-visuals > li:nth-child(5) {
  grid-area: e;
}

section.section-A ul.feat-visuals > li:nth-child(6) {
  grid-area: f;
}

section.section-A ul.feat-visuals > li:nth-child(7) {
  grid-area: g;
}

section.section-A ul.feat-visuals > li:nth-child(8) {
  grid-area: h;
}

section.section-A ul.feat-visuals > li:nth-child(9) {
  grid-area: i;
}

section.section-A ul.feat-visuals > li:nth-child(10) {
  grid-area: j;
}

section.section-A + svg.wave {
  background-color: #003865;
}

section.section-A + svg.wave * {
  fill: #f1f1e6;
}

div.backdrop {
  -webkit-backdrop-filter: blur(.5em);
  backdrop-filter: blur(.5em);
  z-index: 5;
  background-color: #00000080;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

div.mobile-menu-modal.active {
  display: flex;
}

div.backdrop.active {
  display: block;
}

div.mobile-menu-modal {
  z-index: 10;
  text-align: left;
  background-color: #fff;
  border-radius: 1em;
  flex-direction: row;
  width: 85vw;
  padding: 2em;
  display: none;
  position: fixed;
  top: 2%;
  left: 2%;
}

div.mobile-menu-modal img {
  width: 4em;
  position: absolute;
  top: 2em;
  right: 2em;
}

div.mobile-menu-modal ul {
  flex-direction: column;
  list-style-type: none;
  display: flex;
}

div.mobile-menu-modal ul li {
  margin: 1em 0;
}

div.mobile-menu-modal ul a {
  color: inherit;
  font-size: 2em;
  font-weight: bold;
  text-decoration: none;
}

div.mobile-menu-icons {
  align-items: center;
  display: flex;
}

div.sticky-c2a {
  bottom: calc(env(safe-area-inset-bottom)  + 3em);
  color: #fff;
  z-index: 15;
  background-color: #003865;
  border-radius: 9999px;
  padding: .75em;
  position: fixed;
  right: 3em;
  box-shadow: 0 0 10px #00000080;
}

div.sticky-c2a a {
  color: #fff;
  align-items: center;
  text-decoration: none;
  display: flex;
}

div.sticky-c2a:hover {
  cursor: pointer;
  background-color: #00467f;
}

html {
  scroll-behavior: smooth;
}

@media (max-width: 1100px) {
  body {
    font-size: 60%;
  }
}

@media (max-width: 700px) {
  body {
    font-size: 45%;
  }

  body section.section-D {
    padding: 4em 0 5em;
    overflow-x: hidden;
  }

  body section.section-D div.text {
    padding-left: .25em;
    padding-right: .25em;
    font-size: 2em;
  }

  body section.section-D ul.feat-visuals {
    grid-template-areas: "\35  \35  \35  \35  \35  \35  \35  c c c c \37 "
                         "a a a b b b b c c c c \37 "
                         "a a a b b b b c c c c \37 "
                         "a a a b b b b d d e e \37 "
                         "a a a b b b b d d e e \37 "
                         "a a a b b b b d d e e \37 "
                         "a a a b b b b d d e e \37 "
                         "a a a g g g h h h h i i"
                         "\31  f f g g g h h h h i i"
                         "\31  f f g g g h h h h i i"
                         "\31  f f g g g h h h h i i"
                         "\31  f f g g g h h h h \32  \32 "
                         "\31  f f \34  \34  \34  h h h h \32  \32 ";
    margin: 0 -50%;
  }
}

body {
  background: #252525;
}

section.section-D {
  color: #252525;
  text-align: center;
  background-color: #003865;
  padding: 4em 4em 5em;
}

section.section-D div.text::-webkit-scrollbar {
  display: none;
}

section.section-D li.text {
  position: relative;
}

section.section-D li.text svg {
  filter: drop-shadow(0 4px #0000004d);
  fill: #fff;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 10% !important;
  height: 10% !important;
}

section.section-D li.text {
  border-radius: 1em;
}

section.section-D .small-blue-wiggle {
  width: 8em;
  bottom: 10em;
  right: 30em;
}

section.section-D .big-blue-wiggle {
  width: 10em;
  top: 25%;
  left: 30%;
}

section.section-D em {
  color: #003865;
  font-style: normal;
}

section.section-D h3 {
  color: #fff;
  text-shadow: 0 .15em #000;
  margin-bottom: .5em;
  font-size: 4em;
  font-weight: 900;
}

section.section-D h4 {
  margin-top: 0;
  font-size: 2em;
}

section.section-D .strong {
  background-color: #df97df;
  border-radius: 9999px;
  align-items: flex-end;
  height: .5em;
  display: inline-flex;
  position: relative;
  top: .1em;
}

section.section-D .strong strong {
  font-weight: 900;
  position: relative;
  top: -.1em;
}

section.section-D ul.feat-visuals {
  grid-template-rows: repeat(13, 1fr);
  grid-template-columns: repeat(12, 1fr);
  grid-template-areas: "\35  \35  \35  \35  \35  \35  \35  c c c c \37 "
                       "a a a b b b b c c c c \37 "
                       "a a a b b b b c c c c \37 "
                       "a a a b b b b d d e e \37 "
                       "a a a b b b b d d e e \37 "
                       "a a a b b b b d d e e \37 "
                       "a a a b b b b d d e e \37 "
                       "a a a g g g h h h h i i"
                       "\31  f f g g g h h h h i i"
                       "\31  f f g g g h h h h i i"
                       "\31  f f g g g h h h h i i"
                       "\31  f f g g g h h h h \32  \32 "
                       "\31  f f \34  \34  \34  h h h h \32  \32 ";
  gap: 2em 1em;
  width: 60em;
  height: 35em;
  margin-top: 2em;
  padding: 0;
  display: inline-grid;
}

section.section-D ul.feat-visuals > li {
  margin: 0;
  list-style-type: none;
}

section.section-D ul.feat-visuals > li > * {
  border-radius: inherit;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: 0;
  display: inline-flex;
}

section.section-D ul.feat-visuals > li figure {
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: 0 0 1em #00000080;
}

section.section-D ul.feat-visuals > li figure img {
  object-fit: cover;
  border-radius: inherit;
  width: 100%;
  height: 100%;
}

section.section-D ul.feat-visuals > li div {
  color: #fff;
  box-sizing: border-box;
  text-align: center;
  background-color: #009ee0;
  border: none;
  justify-content: start;
  padding-left: 1em;
  padding-right: 1em;
  overflow-y: scroll;
}

section.section-D ul.feat-visuals > li div.blue {
  background-color: #009ee0;
  border: .6em solid #252525;
  font-size: 1.5em;
}

section.section-D ul.feat-visuals > li div.purple {
  background-color: #d80e62;
}

section.section-D ul.feat-visuals > li div.dark {
  background-color: #252525;
}

section.section-D ul.feat-visuals > li div.dark p {
  font-size: 2em;
}

section.section-D ul.feat-visuals > li div.dark a {
  color: #fff;
  font-size: 1.2em;
}

section.section-D ul.feat-visuals > li div.dark:has( > a) {
  border-radius: 2.5em;
}

section.section-D ul.feat-visuals > li div.dark.overflow {
  height: 120%;
  position: relative;
}

section.section-D ul.feat-visuals > li ul.feat-socials {
  text-align: center;
  height: auto;
  padding: 0;
  display: inline;
}

section.section-D ul.feat-visuals > li ul.feat-socials > li {
  border: .1em solid #fff;
  border-radius: 3em;
  flex-direction: column;
  justify-content: center;
  width: 2em;
  height: 2em;
  margin: .2em;
  list-style-type: none;
  display: inline-flex;
}

section.section-D ul.feat-visuals > li ul.feat-socials > li a {
  color: #252525;
  text-decoration: none;
}

section.section-D ul.feat-visuals > li:has( > ul.feat-socials) {
  flex-direction: column;
  justify-content: center;
  display: inline-flex;
}

section.section-D ul.feat-visuals > li:first-child {
  grid-area: a;
}

section.section-D ul.feat-visuals > li:nth-child(2) {
  grid-area: b;
}

section.section-D ul.feat-visuals > li:nth-child(3) {
  grid-area: c;
}

section.section-D ul.feat-visuals > li:nth-child(4) {
  grid-area: d;
}

section.section-D ul.feat-visuals > li:nth-child(5) {
  grid-area: e;
}

section.section-D ul.feat-visuals > li:nth-child(6) {
  grid-area: f;
}

section.section-D ul.feat-visuals > li:nth-child(7) {
  grid-area: g;
}

section.section-D ul.feat-visuals > li:nth-child(8) {
  grid-area: h;
}

section.section-D ul.feat-visuals > li:nth-child(9) {
  grid-area: i;
}

section.section-D + svg.wave {
  background-color: #000;
}

section.section-D + svg.wave * {
  fill: #003865;
}

@media (max-width: 700px) {
  h3 {
    font-size: 4em;
  }

  h4 {
    font-size: 2em;
  }

  ul.feat-requirements li {
    font-size: 1.5em;
  }
}

section.section-F {
  color: #fff;
  background-color: #000;
  flex-direction: column;
  align-items: center;
  padding-top: 10em;
  padding-bottom: 10em;
  display: flex;
}

section.section-F .strong {
  background-color: #009ee0;
  border-radius: 9999px;
  align-items: flex-end;
  height: .5em;
  display: inline-flex;
  position: relative;
  top: .1em;
}

section.section-F .strong strong {
  filter: drop-shadow(0 .1em #000);
  position: relative;
  top: -.1em;
}

section.section-F h3 {
  font-size: 4em;
}

section.section-F h4 {
  text-align: center;
  font-size: 1.5em;
}

section.section-F ul.feat-requirements {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 60em;
  padding: 0;
  list-style-type: none;
  display: flex;
}

section.section-F ul.feat-requirements a {
  text-decoration: none;
}

section.section-F ul.feat-requirements li {
  color: #000;
  background-color: #fff;
  border-radius: 9999px;
  margin: 1em;
  padding: .5em 1em;
  box-shadow: 0 .5em #009ee0;
}

section.section-F ul.feat-requirements li.link:hover {
  box-shadow: none;
  transform: translateY(.5em);
}

section.section-F + svg.wave {
  background-color: #009ee0;
}

section.section-F + svg.wave * {
  fill: #000;
}

section.section-I {
  color: #fff;
  background-color: #009ee0;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  padding: 4em 4em 5em;
  display: flex;
}

section.section-I .star {
  width: 5em;
  bottom: 10%;
  right: 10%;
}

section.section-I div.inner {
  flex-wrap: wrap;
  gap: 4em;
  max-width: 60em;
  display: flex;
}

section.section-I div.inner * {
  flex: 25em;
}

section.section-I div.inner figure {
  align-items: center;
  margin: 0;
  display: flex;
}

section.section-I div.inner figure img {
  max-width: 90%;
}

section.section-I figure img {
  border: .75em solid #252525;
  border-radius: 1.5em;
}

section.section-I button {
  color: #fff;
  background-color: #252525;
  border-radius: 2.5em;
  padding: 1em 2em;
  font-size: 1em;
  box-shadow: 0 .25em #fff;
}

section.section-I button:hover {
  cursor: pointer;
  box-shadow: none;
  transform: translateY(.25em);
}

section.section-I h3 {
  text-shadow: 0 .15em #000;
  margin-bottom: .5em;
  font-size: 4em;
  font-weight: 900;
}

section.section-I p {
  margin-bottom: 1em;
  font-size: 1.5em;
  line-height: 1.5em;
}

section.section-I .strong {
  background-color: #f1f1e6;
  border-radius: 9999px;
  align-items: flex-end;
  height: .5em;
  display: inline-flex;
  position: relative;
  top: .1em;
}

section.section-I .strong strong {
  font-weight: 900;
  position: relative;
  top: -.1em;
}

section.section-I + svg.wave {
  background-color: #fff;
}

section.section-I + svg.wave * {
  fill: #009ee0;
}

@media (max-width: 450px) {
  section.section-I div.inner {
    flex-direction: column-reverse;
  }
}

section.section-H {
  color: #252525;
  background-color: #fff;
  flex-direction: column;
  align-items: center;
  padding-top: 10em;
  padding-bottom: 10em;
  display: flex;
}

section.section-H .strong {
  background-color: #009ee0;
  border-radius: 9999px;
  align-items: flex-end;
  height: .5em;
  display: inline-flex;
  position: relative;
  top: .1em;
}

section.section-H .strong strong {
  filter: drop-shadow(0 .1em #fff);
  position: relative;
  top: -.1em;
}

section.section-H h3 {
  font-size: 3em;
}

section.section-H ul.feat-requirements {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 60em;
  padding: 0;
  list-style-type: none;
  display: flex;
}

section.section-H ul.feat-requirements li {
  color: #fff;
  background-color: #252525;
  border-radius: 9999px;
  margin: 1em;
  padding: .5em 1em;
  box-shadow: 0 .5em #f1f1e6;
}

section.section-H + svg.wave {
  background-color: #009ee0;
}

section.section-H + svg.wave * {
  fill: #fff;
}

section.section-M {
  color: #fff;
  background-color: #009ee0;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  padding: 4em 4em 5em;
  display: flex;
}

section.section-M div.form-holder {
  background-color: #fff;
  border: .5em solid #252525;
  border-radius: 2em;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 20em;
  padding: 2em;
  display: flex;
  box-shadow: 0 .25em #fff;
}

section.section-M div.form-holder h3 {
  color: #252525;
  text-shadow: 0 .15em #ff0;
}

section.section-M ul {
  padding-inline-start: 0;
  font-size: 1.25em;
}

section.section-M ul li {
  padding-bottom: 1em;
  list-style-type: none;
}

section.section-M span {
  color: #252525;
  justify-content: center;
  padding-top: 1em;
  font-size: .75em;
  font-weight: 700;
  display: flex;
}

section.section-M form {
  color: #252525;
  flex-direction: column;
  gap: .2em;
  font-weight: 600;
  display: flex;
}

section.section-M form label {
  flex-direction: column;
  display: flex;
}

section.section-M form input {
  border: .2em solid #252525;
  border-radius: .5em;
  flex-direction: column;
  padding: .5em 1em;
  font-size: 1.5em;
  display: flex;
}

section.section-M form button {
  color: #fff;
  background-color: #003865;
  align-self: center;
  align-items: center;
  max-height: 2.5em;
  font-size: 1.2em;
  display: flex;
  box-shadow: 0 .25em #000;
}

section.section-M .star {
  width: 5em;
  bottom: 10%;
  right: 10%;
}

section.section-M div.inner {
  flex-wrap: wrap;
  gap: 4em;
  max-width: 60em;
  display: flex;
}

section.section-M div.inner figure {
  align-items: center;
  margin: 0;
  display: flex;
}

section.section-M div.inner figure img {
  max-width: 90%;
}

section.section-M figure img {
  border: .75em solid #252525;
  border-radius: 1.5em;
}

section.section-M button {
  color: #fff;
  background-color: #252525;
  border-radius: 2.5em;
  padding: 1em 2em;
  font-size: 1em;
  box-shadow: 0 .25em #fff;
}

section.section-M button:hover {
  cursor: pointer;
  box-shadow: none;
  transform: translateY(.25em);
}

section.section-M h3 {
  text-shadow: 0 .15em #000;
  margin-bottom: .5em;
  font-size: 3em;
  font-weight: 900;
}

section.section-M p {
  margin-bottom: 1em;
  font-size: 1.5em;
  line-height: 1.5em;
}

section.section-M .strong {
  background-color: #f1f1e6;
  border-radius: 9999px;
  align-items: flex-end;
  height: .5em;
  display: inline-flex;
  position: relative;
  top: .1em;
}

section.section-M .strong strong {
  font-weight: 900;
  position: relative;
  top: -.1em;
}

section.block-F + svg.wave {
  background-color: #f1f1e6;
}

section.block-F + svg.wave * {
  fill: #009ee0;
}

@media (max-width: 600px) {
  section.section-M {
    flex-direction: column;
    justify-content: center;
  }

  section.section-M div.form-holder {
    max-width: 80vw;
  }

  section.section-M div.form-holder form {
    gap: 2em;
  }

  section.section-M div.form-holder form button {
    font-size: 2.25em;
    font-weight: 700;
  }

  section.section-M div.form-holder label {
    font-size: 1.5em;
  }

  section.section-M div.form-holder .form-group {
    width: 100%;
  }

  section.section-M div.form-holder input {
    width: 70vw;
  }

  section.section-M div.form-holder span {
    font-size: 1.2em;
  }
}

section.section-N {
  color: #252525;
  text-align: center;
  background-color: #fff;
  justify-content: center;
  padding: 0 4em 5em;
  display: flex;
}

section.section-N div.inner {
  max-width: 60em;
}

section.section-N h3 {
  font-size: 3em;
}

section.section-N div.below-hr {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

section.section-N div.below-hr * {
  flex: 1;
}

section.section-N div.below-hr figure {
  text-align: left;
  margin: 0;
}

section.section-N div.below-hr figure img {
  max-width: 4em;
}

section.section-N ul {
  list-style-type: none;
}

section.section-N div.feat-buttons {
  justify-content: center;
  margin: 2em 0;
  display: flex;
}

section.section-N div.feat-buttons button {
  color: #252525;
  background-color: #fff;
  border-radius: 9999px;
  margin: 0 1em;
  padding: 1em 2em;
  font-size: 1em;
  box-shadow: 0 .25em #000;
}

section.section-N div.feat-buttons button:hover {
  cursor: pointer;
  box-shadow: none;
  transform: translateY(.25em);
}

section.section-N ul.feat-socials {
  justify-content: center;
  display: flex;
}

section.section-N ul.feat-socials li {
  border: 2px solid #fff;
  border-radius: 9999px;
  justify-content: center;
  max-width: 2.5em;
  max-height: 2.5em;
  margin: 0 1em;
  padding: 1em;
  display: flex;
}

/*# sourceMappingURL=index.56792676.css.map */
